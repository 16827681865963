
$family-sans-serif: sans-serif
$title-weight: 500

// Disable the widescreen breakpoint
//$widescreen-enabled: false

// Disable the fullhd breakpoint
$fullhd-enabled: false

@import '~bulma'
